<template>
  <div>
    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          {{ sidebarTitle }}
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hideSideBar()"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row>
          <b-col md="12">
            <b-form-group
              label="Name"
              invalid-feedback="Name is required."
              ref="name"
            >
              <b-form-input
                id="mc-first-name"
                placeholder="Enter name here"
                v-validate="'required'"
                ref="name"
                v-model="myObj.name"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
              label="Email"
              invalid-feedback="Email is required."
              ref="email"
            >
              <b-form-input
                placeholder="Enter email name here"
                ref="email"
                v-model="myObj.email"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
              label="Contact"
              invalid-feedback="Contact number is required."
            >
              <vue-tel-input v-model="myObj.contact"></vue-tel-input>
              <!-- @blur="CheckContact()" -->
              <small class="mr-1 text-danger" v-if="contacterror"
                >Contact number is required</small
              >
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
              label="Mobile"
              invalid-feedback="Mobile number is required."
            >
              <vue-tel-input v-model="myObj.mobile"></vue-tel-input>
              <!-- @blur="CheckMobile()" -->
              <small class="mr-1 text-danger" v-if="mobileerror"
                >Mobile number is required</small
              >
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
              label="City"
              invalid-feedback="City is required."
              ref="city"
            >
              <b-form-input
                placeholder="Enter city name here"
                ref="city"
                v-model="myObj.city"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
              label="Address"
              invalid-feedback="Address is required."
              ref="address"
            >
              <b-form-input
                placeholder="Enter address name here"
                ref="address"
                v-model="myObj.address"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
              label="Category"
              invalid-feedback="Category  is required."
              ref="category"
            >
              <v-select
                ref="category"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :clearable="false"
                :options="cateOptions"
                :reduce="(opt) => opt.value"
                label="text"
                v-model="myObj.category"
                placeholder="Select category"
                @change="CheckCategory()"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
              label="Tax Percentage"
              invalid-feedback="Tax Percentage is required."
              ref="tax"
            >
              <b-form-input
                type="number"
                placeholder="Enter tax %"
                ref="tax"
                v-model="myObj.tax"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              @click="Add()"
              :disabled="request"
              block
            >
              <b-spinner v-if="request" small type="grow" />
              <span v-else> {{ sidebarButton }} </span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-card>
      <b-row class="mt-1">
        <!-- v-if="rights.add" -->
        <b-col xl="2" lg="3" md="4" sm="12" cols="12">
          <b-button
            @click="AddOpen()"
            style="width: 100%"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
          >
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span class="align-middle">Supplier</span>
          </b-button>
        </b-col>

        <b-col
          class="mt-lg-0 mt-md-0 mt-sm-1 mt-1"
          xl="10"
          lg="9"
          md="8"
          sm="12"
          cols="12"
        >
          <b-form-group class="">
            <b-input-group>
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>

              <b-form-input v-model="searchQuery" placeholder="Search...">
              </b-form-input>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>

      <b-table
        class="mt-1"
        ref="selectableTable"
        :items="filters"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        :busy="dataLoading"
        responsive
        show-empty
        hover
        :selectable="rights.edit"
        select-mode="single"
        @row-selected="Edit($event[0])"
      >
        <template #table-busy>
          <div
            class="d-flex justify-content-center mb-3"
            style="margin-top: 50px"
          >
            <b-spinner
              style="width: 3rem; height: 3rem"
              type="grow"
              variant="primary"
              label="Spinning"
            ></b-spinner>
          </div>
        </template>
        <template #cell(name)="data">
          <b-badge variant="light-primary">
            {{ data.item.name }}
          </b-badge>
        </template>
        <template #cell(email)="data">
          <b-badge variant="light-primary">
            {{ data.item.email }}
          </b-badge>
        </template>
        <template #cell(category)="data">
          <b-badge variant="light-primary">
            {{ data.item.category }}
          </b-badge>
        </template>
        <template #cell(contact)="data">
          <b-badge variant="light-primary">
            {{ data.item.contact }}
          </b-badge>
        </template>

        <template #head(actions)="data">
          <div class="text-center">
            <span>{{ data.label }}</span>
          </div>
        </template>

        <template #cell(actions)="data">
          <div class="text-center">
            <!-- v-if="rights.edit" -->
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              v-b-tooltip.hover.left
              title="Edit"
              class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
              @click="Edit(data.item)"
            >
              <feather-icon icon="EditIcon" class="" />
            </b-button>

            <!-- v-if="rights.delete" -->
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-danger"
              v-b-tooltip.hover.right
              title="Delete"
              class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
              @click="Delete(data.item.id)"
            >
              <feather-icon icon="Trash2Icon" />
            </b-button>
          </div>
        </template>
      </b-table>
      <b-col cols="12" class="d-flex align-items-center justify-content-center">
        <b-pagination
          v-model="currentPage"
          :total-rows="filters.length"
          :per-page="perPage"
          first-number
          last-number
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </b-col>
    </b-card>
  </div>
</template>

<script>
import { required, minLength, between } from "vuelidate/lib/validators";
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BMediaAside,
  BAside,
  BSpinner,
  BCardTitle,
  BCardText,
  BSidebar,
  VBToggle,
  BInputGroupPrepend,
  BInputGroup,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import store from "@/store";
import { avatarText } from "@core/utils/filter";
import flatPickr from "vue-flatpickr-component";
import { BFormSelect } from "bootstrap-vue";
//import Multiselect from "vue-multiselect";
import VueGallery from "vue-gallery";
import { forEach } from "postcss-rtl/lib/affected-props";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    //Multiselect,

    BCard,
    BRow,
    BBadge,
    BTable,
    BCol,
    BFormInput,
    BButton,
    BSidebar,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    // BFormCheckbox,
    BForm,
    BPagination,
    BSpinner,

    BDropdown,
    BDropdownItem,

    vSelect,
  },
  props: {},
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  async created() {
    //   let right = {};
    //   this.$store.state.menu.forEach((el) => {
    //     el.children.forEach((ch) => {
    //       right[ch.route] = {
    //         view: ch.view,
    //         add: ch.add,
    //         edit: ch.edit,
    //         delete: ch.delete,
    //       };
    //     });
    //   });
    //   this.$store.commit("setRights", right);
    //   if (!this.$store.state.rights[this.$route.name]) {
    //     this.$router.replace({
    //       name: "misc-not-authorized",
    //     });
    //   } else {
    //     this.rights = this.$store.state.rights[this.$route.name];
    //     this.LoadData();
    // }
    // this.LoadData();
  },
  computed: {
    filters: function () {
      return this.items.filter((pro) => {
        return pro.name.toLowerCase().match(this.searchQuery.toLowerCase());
      });
    },
  },
  data() {
    return {
      rights: {},
      myDepartments: [],
      request: false,
      colorDep: [],
      visibility1: false,

      sidebarTitle: "Add Account",
      sidebarButton: "Save",
      buttonIcon: "PlusIcon",
      dataLoading: false,

      visibility: false,
      logoloading: "",
      searchQuery: "",
      currentPage: 1,
      perPage: 30,
      totalRows: 0,

      errors: {
        status: false,
      },

      index: null,

      fields: [
        { label: "name", key: "name" },
        { label: "email", key: "email" },
        { label: "contact", key: "contact" },
        { label: "category", key: "category" },
        "actions",
      ],
      items: [],
      cateOptions: [
        { text: "Service provider", value: "service" },
        { text: "Products", value: "products" },
      ],
      selected: null,
      rangeDate: null,
      myObj: {
        id: 0,
        name: "",
        contact: "",
        email: "",
        mobile: "",
        address: "",
        city: "",
        category: "",
        tax: 0,
        campusID: this.$store.state.userData.cId,
      },

      detailObj: {},
      timeout: 10000,
    };
  },
  validations: {
    depObj: {
      name: {
        required,
        minLength: minLength(4),
      },
    },
    age: {
      between: between(20, 30),
    },
  },
  methods: {
    ...mapActions({ getDepartment: "getDepartment" }),
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),

    async Edit(item) {
      this.myObj = { ...item };
      this.visibility = true;
      this.sidebarTitle = "Edit Supplier";
      this.sidebarButton = "Update";
      var elem = this.$refs["name"];
      elem.state = undefined;
      var elem = this.$refs["email"];
      elem.state = undefined;
      var elem = this.$refs["address"];
      elem.state = undefined;
      var elem = this.$refs["city"];
      elem.state = undefined;
      var elem = this.$refs["category"];
      elem.state = undefined;
      var elem = this.$refs["tax"];
      elem.state = undefined;
    },

    AddOpen() {
      this.myObj = {
        id: 0,
        name: "",
        contact: "",
        email: "",
        mobile: "",
        address: "",
        city: "",
        category: "",
        tax: 0,
        campusID: this.$store.state.userData.cId,
      };

      this.visibility = true;
      this.sidebarTitle = "Add Supplier";
      this.sidebarButton = "Save";
      var elem = this.$refs["name"];
      elem.state = undefined;
      var elem = this.$refs["email"];
      elem.state = undefined;
      var elem = this.$refs["address"];
      elem.state = undefined;
      var elem = this.$refs["city"];
      elem.state = undefined;
      var elem = this.$refs["category"];
      elem.state = undefined;
      var elem = this.$refs["tax"];
      elem.state = undefined;
    },

    CheckName() {
      var elem = this.$refs["name"];
      if (this.myObj.name == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckTax() {
      var elem = this.$refs["tax"];
      if (isNaN(parseInt(this.myObj.tax))) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckCode() {
      var elem = this.$refs["code"];
      if (this.myObj.code === "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckContact() {
      if (this.myObj.contact.length == 12) {
        return (this.contacterror = false);
      } else {
        return (this.contacterror = true);
      }
    },
    CheckMobile() {
      if (this.myObj.mobile.length == 12) {
        return (this.mobileerror = false);
      } else {
        return (this.mobileerror = true);
      }
    },
    CheckCategory() {
      var elem = this.$refs["category"];
      if (this.myObj.category == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },

    hideSideBar() {
      this.visibility = false;
    },

    async LoadData() {
      this.dataLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          " ?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.items = await this.get(obj);
      // console.log("it", this.items);

      this.dataLoading = false;
    },

    async Add() {
      this.CheckName();
      this.CheckTax();
      this.CheckContact();
      this.CheckCategory();
      if (
        this.CheckName() == true &&
        this.CheckContact() == true &&
        this.CheckTax() == true &&
        this.CheckCategory() == true
      ) {
        this.myObj.tax = parseInt(this.myObj.tax);
        console.log("obj:", this.myObj);
        // this.request = true;
        // var status = await this.post({
        //   url:
        //     this.$store.state.domain +
        //     "?db=" +
        //     this.$store.state.userData.db +
        //     "&cID=" +
        //     this.$store.state.userData.cId,
        //   body: this.myObj,
        //   message: "Supplier added successfully.",
        //   context: this,
        //   token: this.$store.state.userData.token,
        // });
        // this.request = false;
        // if (status) {
        //   this.LoadData();
        //   this.visibility = false;
        // }
      }
    },

    async Delete(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      });
      if (result.isConfirmed) {
        // var status = await this.delete({
        //   url:
        //     this.$store.state.domain +
        //     "/" +
        //     id +
        //     "?db=" +
        //     this.$store.state.userData.db,
        //   message: "Supplier removed successfully.",
        //   context: this,
        //   token: this.$store.state.userData.token,
        // });
        // if (status) this.LoadData();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
.card {
  transition: 0.4s;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
</style>
